import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from "reactstrap";
import { Button } from "reactstrap";
import axios from "axios";
import validation from '../Validations/Buyer-All.js';

const FeatureOne = () => {

  const [clients, setClients] = useState([]);
  const [message, setMessage] = useState("");
  const [messageerror, setMessageerror] = useState("");
  const [values, setValues] = useState({
    name: '',
    panno: '',
    contact: '',
    email: '',
    image1: null, // Added state for image 1
    image2: null, // Added state for image 2
    image3: null, // Added state for image 3
    image4: null, // Added state for image 4
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    // Fetch data from the API endpoint
    axios.get('https://bidtowin-heroku-825dede58502.herokuapp.com/getBuyersData')
      .then(response => {
        // Store the fetched data in the state
        setClients(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []); // Run this effect only once when the component mounts

  const handleInput = (event) => {
    setValues(prev => ({ ...prev, [event.target.name]: event.target.value }));
  }

  const handleFileChange = (event, imageNumber) => {
    const file = event.target.files[0];
    setValues(prev => ({ ...prev, [`image${imageNumber}`]: file }));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrors(validation(values));

    if (errors.name === "" && errors.panno === "" && errors.contact === "" && errors.email === "") {
      // Here you can also validate the images before submission
      // For simplicity, I'm just checking if all four images are selected
      if (values.image1 && values.image2 && values.image3 && values.image4) {
        // If all images are selected, proceed with the form submission
        const formData = new FormData();
        formData.append('name', values.name);
        formData.append('panno', values.panno);
        formData.append('contact', values.contact);
        formData.append('email', values.email);
        formData.append('image1', values.image1);
        formData.append('image2', values.image2);
        formData.append('image3', values.image3);
        formData.append('image4', values.image4);

        axios.post('https://bidtowin-heroku-825dede58502.herokuapp.com/buyers', formData)
          .then(res => {
            if (res.data === "Exists") {
              setMessageerror("Buyer Email Exists! Please Recheck & Try Again.");
              setMessage("");
            } else {
              setMessage("Operation Success! Record was created successfully!");
              setMessageerror("");
              event.target.reset();

              // Fetch data from the API endpoint
              axios.get('https://bidtowin-heroku-825dede58502.herokuapp.com/getBuyersData')
                .then(response => {
                  // Store the fetched data in the state
                  setClients(response.data);
                })
                .catch(error => {
                  console.error('Error fetching data:', error);
                });
            }
          })
          .catch(err => console.log(err));
      } else {
        // If any image is not selected, show error message
        setErrors(prev => ({
          ...prev,
          image1: !values.image1 ? "Please select Image 1" : "",
          image2: !values.image2 ? "Please select Image 2" : "",
          image3: !values.image3 ? "Please select Image 3" : "",
          image4: !values.image4 ? "Please select Image 4" : "",
        }));
      }
    }
  }

  var bgColors = { "light": "white",
  "greyish": "#2F2F2E"
};

const handleDelete = (clientId) => {
  // Make an API call to delete the client
  axios.delete(`https://bidtowin-heroku-825dede58502.herokuapp.com//buyers/${clientId}`)
    .then(response => {
      // If deletion is successful, update the clients array to remove the deleted client
      setClients(clients.filter(client => client.Id !== clientId));
    })
    .catch(error => {
      console.error('Error deleting client:', error);
      // Handle error, such as displaying an error message
    });
};

  return (
    <div style={{ paddingTop: 90 }}>
      <Container>
        <Row className='align-items-center'>
          <Col xl="12">
            <div>
              <div style={{ marginBottom: 10 }}>
                <h5 style={{ fontWeight: "bold", color: "brown", marginBottom: 0 }}>Create / Manage Buyers</h5>
                <p style={{ color: 'GrayText' }}>The following control helps in creating / managing new / existing buyer records.</p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="5">
            <div>
              <div style={{ padding: 10, margin: "10px 2px", border: "solid #fafafa 2px", borderRadius: "5px", backgroundColor: bgColors.light }}>
                {message && <p style={{ padding: 5, backgroundColor: "green", color: "white", borderRadius: 2 }}>{message}</p>}
                {messageerror && <p style={{ padding: 5, backgroundColor: "red", color: "white", borderRadius: 2 }}>{messageerror}</p>}
                <h6 style={{ fontWeight: "bold", color: "black" }}>A. Create New Record</h6>
                <hr style={{ color: bgColors.greyish, margin: "0.5rem 0" }} />
                <p style={{ marginBottom: "0.5rem" }}>Use the following form to create new Buyer record.</p>

                <form className="is-alter" action="" onSubmit={handleSubmit}>
                 
                  <div className="form-group">
                    <label className="form-label" htmlFor="name">
                      Full Name
                    </label>
                    <div className="form-control-wrap">
                      <input onChange={handleInput}
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Enter Buyer Name"
                        className="form-control-lg form-control"
                      />
                       {errors.name && <span className="text-danger" style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>{errors.name}</span>}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="panno">
                      PAN No.
                    </label>
                    <div className="form-control-wrap">
                      <input onChange={handleInput}
                        type="text"
                        id="panno"
                        name="panno"
                        placeholder="Enter PAN No."
                        className="form-control-lg form-control"
                      />
                      {errors.panno && <span className="text-danger" style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>{errors.panno}</span>}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="contact">
                      Contact No.
                    </label>
                    <div className="form-control-wrap">
                      <input onChange={handleInput}
                        type="text"
                        id="contact"
                        name="contact"
                        placeholder="Enter Contact No."
                        className="form-control-lg form-control"
                      />
                      {errors.contact && <span className="text-danger" style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>{errors.contact}</span>}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="email">
                      Email Address
                    </label>
                    <div className="form-control-wrap">
                      <input onChange={handleInput}
                        type="text"
                        id="email"
                        name="email"
                        placeholder="Enter Email Address"
                        className="form-control-lg form-control"
                      />
                      {errors.email && <span className="text-danger" style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>{errors.email}</span>}
                    </div>
                  </div>

                  {/* Image uploader 1 */}
                  <div className="form-group">
                    <label className="form-label" htmlFor="image1">
                      Upload PAN
                    </label>
                    <div className="form-control-wrap">
                      <input onChange={(e) => handleFileChange(e, 1)}
                        type="file"
                        id="image1"
                        name="image1"
                        accept="image/*" // Accept only image files
                        className="form-control-lg form-control"
                      />
                      {errors.image1 && <span className="text-danger" style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>{errors.image1}</span>}
                    </div>
                  </div>

                  {/* Image uploader 2 */}
                  <div className="form-group">
                    <label className="form-label" htmlFor="image2">
                      Upload Aadhar
                    </label>
                    <div className="form-control-wrap">
                      <input onChange={(e) => handleFileChange(e, 2)}
                        type="file"
                        id="image2"
                        name="image2"
                        accept="image/*" // Accept only image files
                        className="form-control-lg form-control"
                      />
                      {errors.image2 && <span className="text-danger" style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>{errors.image2}</span>}
                    </div>
                  </div>

                  {/* Image uploader 3 */}
                  <div className="form-group">
                    <label className="form-label" htmlFor="image3">
                      Upload Payment Receipt
                    </label>
                    <div className="form-control-wrap">
                      <input onChange={(e) => handleFileChange(e, 3)}
                        type="file"
                        id="image3"
                        name="image3"
                        accept="image/*" // Accept only image files
                        className="form-control-lg form-control"
                      />
                      {errors.image3 && <span className="text-danger" style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>{errors.image3}</span>}
                    </div>
                  </div>

                  {/* Image uploader 4 */}
                  <div className="form-group">
                    <label className="form-label" htmlFor="image4">
                      Upload Cancelled Cheque
                    </label>
                    <div className="form-control-wrap">
                      <input onChange={(e) => handleFileChange(e, 4)}
                        type="file"
                        id="image4"
                        name="image4"
                        accept="image/*" // Accept only image files
                        className="form-control-lg form-control"
                      />
                      {errors.image4 && <span className="text-danger" style={{ fontSize: "0.8rem", fontWeight: "bolder" }}>{errors.image4}</span>}
                    </div>
                  </div>

                  <div className="form-group">
                    <Button type="submit" color="primary" size="lg" className="btn-block">
                      Create Record
                    </Button>
                  </div>
                </form>

              </div>

            </div>
          </Col>
          <Col  md="7">
                        <div>
                        <div style={{padding:10, margin:"10px 2px", border:"solid #fafafa 2px", borderRadius:"5px", minHeight:30,backgroundColor:bgColors.light}}>
                        <h6 style={{fontWeight:"bold",color:"black"}}>B. Manage Existing Records</h6>
                        <hr style={{color:bgColors.greyish,margin:"0.5rem 0"}}/>
                        <p style={{marginBottom:"0.5rem"}}>Use the following datalist to review / delete existing records.</p>
                        <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '20px' }}>
  <thead>
    <tr>
      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left', color:"white", backgroundColor:"brown", fontWeight:"normal", fontSize: "0.9rem" }}>Name</th>
      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left',  color:"white", backgroundColor:"brown", fontWeight:"normal", fontSize: "0.9rem" }}>PAN No.</th>
      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left',  color:"white", backgroundColor:"teal", fontWeight:"normal", fontSize: "0.9rem" }}>Contact No.</th>
      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left',  color:"white", backgroundColor:"teal", fontWeight:"normal", fontSize: "0.9rem" }}>Email Address</th>
      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left',  color:"white", backgroundColor:"teal", fontWeight:"normal", fontSize: "0.9rem" }}>Actions</th>
    </tr>
  </thead>
  <tbody>
    {clients.map(client => (
      <tr key={client.Id} style={{ border: '1px solid #dddddd' }}>
        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left',  fontSize: "0.9rem", color:"black" }}>{client.Name}</td>
        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left',  fontSize: "0.9rem", color:"black" }}>{client.Panno}</td>
        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left',  fontSize: "0.9rem", color:"black" }}>{client.Contact}</td>
        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left',  fontSize: "0.9rem", color:"black" }}>{client.Email}</td>
        <td style={{ border: '1px solid #dddddd', padding: '2px 8px', textAlign: 'left' }}>
        <Button className="btn-warning btn-small" style={{padding:"2px 5px", color:"black"}}>Manage</Button> &nbsp;
          <Button className="btn-secondary btn-small" style={{padding:"2px 5px"}} onClick={() => {
    if (window.confirm("Are you sure you want to delete this record?")) {
      handleDelete(client.Id);
    }
  }}>Delete</Button>
        </td>
      </tr>
    ))}
  </tbody>
</table>

                        </div>
                        </div>
                    </Col>
        </Row>
      </Container>
    </div>
  )
}

export { FeatureOne };
