import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from "reactstrap"
import {Link} from "../components/button/Button"
import moment from 'moment';
import axios from "axios";


const FeatureOne = (props) =>{
    var bgColors = { "light": "white",
        "greyish": "#2F2F2E"
    };

    const [date, setDate] = useState(moment());

    const formattedDate = moment(date).format('YYYY-MM-DD');
  
    const [clients, setClients] = useState([]);

    useEffect(() => {
      // Fetch data from the API endpoint
      axios.get('https://bidtowin-heroku-825dede58502.herokuapp.com/getAuctionsData')
        .then(response => {
          // Store the fetched data in the state
          setClients(response.data);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }, []); // Run this effect only once when the component mounts 
    

    return(
        <div style={{paddingTop:90}}>
            <Container>
            <Row className='align-items-center'>
                    <Col xl="12">
                        <div>
                            <div style={{marginBottom:10}}>
                           <h5 style={{fontWeight:"bold",color:"brown",marginBottom:0}}>Content & Data Management Services</h5>
                           <p style={{color:'GrayText'}}>Welcome to <b>Dedicated Administrator Panel for your Web and App</b> services.</p>
                           
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="5">
                        <div>
                        <div style={{padding:10, margin:"10px 2px", border:"solid #fafafa 2px", borderRadius:"5px",backgroundColor:bgColors.light}}>
                           <h6 style={{fontWeight:"bold",color:"black"}}>A. Auctions & Listings</h6>
                           <hr style={{color:bgColors.greyish,margin:"0.5rem 0"}}/>
                           <p style={{marginBottom:"0.5rem"}}>Create new or manage existing auctions. List data available for all auctions.</p>
                         
                           <ul className="header-action btns-inline" style={{paddingTop:0}}>
                                 <li style={{paddingRight:0}}>
                                    <Link to={`${process.env.PUBLIC_URL}/auctions`} className="btn-secondary btn-small"><span>Manage Auctions</span></Link>
                                </li>
                                <li style={{paddingRight:0}}>
                                    <Link to={`${process.env.PUBLIC_URL}/auctionlist`} className="btn-secondary btn-small"><span>All Auctions</span></Link>
                                </li>
                            </ul>
                            </div>
                         
                            <div style={{padding:10, margin:"10px 2px", border:"solid #fafafa 2px", borderRadius:"5px",backgroundColor:bgColors.light}}>
                           <h6 style={{fontWeight:"bold",color:"black"}}>B. Buyers & Details</h6>
                           <hr style={{color:bgColors.greyish,margin:"0.5rem 0"}}/>
                           <p style={{marginBottom:"0.5rem"}}>Create new Buyer or manage an existing one. List data available for all buyers.</p>
                          
                           <ul className="header-action btns-inline" style={{paddingTop:0}}>
                           <li  style={{paddingRight:0}}>
                                    <Link to={`${process.env.PUBLIC_URL}/manage`} className="btn-secondary btn-small"><span>Manage Buyers</span></Link>
                                </li>
                                 <li  style={{paddingRight:0}}>
                                    <Link to={`${process.env.PUBLIC_URL}/monitoring`} className="btn-secondary btn-small"><span>Search Details</span></Link>
                                </li>
                                
                            </ul>
                            </div>
                            <div style={{padding:10, margin:"10px 2px", border:"solid #fafafa 2px", borderRadius:"5px",backgroundColor:bgColors.light}}>
                           <h6 style={{fontWeight:"bold",color:"black"}}>C. System Resources</h6>
                           <hr style={{color:bgColors.greyish,margin:"0.5rem 0"}}/>
                           <p style={{marginBottom:"0.5rem"}}>Create or manage clients and categories for form filling and review new buyer requests.</p>
                          
                           <ul className="header-action btns-inline" style={{paddingTop:0}}>
                                 <li  style={{paddingRight:0}}>
                                    <Link to={`${process.env.PUBLIC_URL}/clients`} className="btn-secondary btn-small"><span>Clients</span></Link>
                                </li>
                                <li  style={{paddingRight:0}}>
                                    <Link to={`${process.env.PUBLIC_URL}/categories`} className="btn-secondary btn-small"><span>Categories</span></Link>
                                </li>
                                <li  style={{paddingRight:0}}>
                                    <Link to={`${process.env.PUBLIC_URL}/interests`} className="btn-secondary btn-small"><span>Buyer Interests</span></Link>
                                </li>
                              
                            </ul>
                            </div>
                        </div>
                    </Col>
                    <Col  md="7">
                        <div>
                        <div style={{padding:10, margin:"10px 2px", border:"solid #fafafa 2px", borderRadius:"5px", minHeight:30,backgroundColor:bgColors.light}}>
                        <h6 style={{fontWeight:"bold",color:"black"}}>Showing All Listed Auctions <span style={{float:'right'}}>(as on) <span style={{color:"blueviolet"}}>{formattedDate}</span></span></h6>
                        <hr style={{color:bgColors.greyish,margin:"0.5rem 0"}}/>
                        <p style={{marginBottom:"0.5rem"}}>Get a quick peak inside all listed auctions on the BidToWin App. For managing existing auctions kindly visit the manage auctions section.</p>

                        <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '20px' }}>
  <thead>
    <tr>
      <th style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left', color:"white", backgroundColor:"brown", fontWeight:"normal", fontSize: "0.9rem" }}>Auction Details</th>
      <th style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left',  color:"white", backgroundColor:"cadetblue", fontWeight:"normal", fontSize: "0.9rem" }}>End Timestamp</th>
      
    </tr>
  </thead>
  <tbody>
    {clients.map(client => (
      <tr key={client.Id} style={{ border: '1px solid #dddddd' }}>
        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left',  fontSize: "0.9rem", color:"black" }}><b>{client.Name}</b><br/>{client.Client} / {client.Category} / {client.Location}</td>
        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left',  fontSize: "0.9rem", color:"black" }}>{client.Enddate} / {client.Endtime}</td>
        
      </tr>
    ))}
  </tbody>
</table>


                        </div>
                        </div>
                    </Col>
                </Row>
                
            </Container>
        </div>
    )
}



export {FeatureOne}
