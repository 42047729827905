import React, { useState, useEffect } from 'react'

import LogoDrak2x from '../Resc/header-black.png'
import LogoLight2x from '../Resc/header-white.png'

import Menu from '../layout/menu/Menu'
import MobileMenu from '../layout/menu/MobileMenu'
import {Logo} from "../components/logo/Logo"
import { Col, Container, Row, Button } from "reactstrap"
import {HeaderWrap, HeaderMain} from "../layout/header/Header2"
import { HeaderCaption} from '../components/headerCaption/HeaderCaption'
import {Link} from "../components/button/Button"
import {BannerOneData} from './BannerData'



const BannerOne = (props) =>{
    const [toggle, setToggle] = useState(false);
    const [offset, setOffset] = useState(0);
    const [mobileView, setMobileView] = useState(false);

    useEffect(() => {
        window.onscroll = () => {
        setOffset(window.pageYOffset)
        }
        window.scrollTo(0,0);
        viewChange();
        window.addEventListener("resize", viewChange);
        return () => {
            window.removeEventListener('resize', viewChange);
        };
    }, []);

    // function to change the design view under 1200 px
    const viewChange = () => {
        if (window.innerWidth < 992) {
        setMobileView(true);
        } else {
        setMobileView(false);
        }
    };

   

    var bgColors = { "dark": "#434342"};
    
   

    return(
    <div  style={{backgroundColor:bgColors.dark}}>
        <HeaderMain className={`header-main header-main-s1 is-sticky has-fixed`}>
            <Container className="header-container">
                <HeaderWrap>
                    <div className="header-logo">
                        <Logo to="/"
                        dark ={LogoDrak2x}
                        light ={LogoLight2x}
                        />
                    </div>
                    <div className="header-toggle" onClick={() => setToggle(!toggle)}>
                        <button className={`menu-toggler ${toggle === true ? "active" : ""}`}> 
                            <em className="menu-on icon ni ni-menu"></em>
                            <em className="menu-off icon ni ni-cross"></em>
                        </button>
                    </div>
                    <nav className={`header-menu menu ${toggle === true ? "active" : ""} ${mobileView ? "mobile-menu" : ""}`}> 
                        {!mobileView ? <Menu className="ms-lg-auto" data={BannerOneData} /> : <MobileMenu data={BannerOneData}/>}
                        <ul className="menu-btns">
                        <li>
                                    <Link to={`${process.env.PUBLIC_URL}/settings`} className="btn-primary btn-lg"><span>Settings</span></Link>
                                </li>
                        </ul>
                    </nav>
                    {toggle && window.innerWidth < 992 && <div className="header-overlay" onClick={() => setToggle(!toggle)}></div>}
                </HeaderWrap>
            </Container>
        </HeaderMain>
       
    </div>
    )
}


export default BannerOne
