import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Col, Container, Row, Button } from "reactstrap";

function FeatureOne() {
  const [clients, setClients] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All"); // Default to show all clients

  useEffect(() => {
    // Fetch data from the API endpoint
    axios.get('https://bidtowin-heroku-825dede58502.herokuapp.com/getAuctionsData')
      .then(response => {
        // Store the fetched data in the state
        setClients(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []); // Run this effect only once when the component mounts

  const handleDelete = (clientId) => {
    // Make an API call to delete the client
    axios.delete(`https://bidtowin-heroku-825dede58502.herokuapp.com/auctions/${clientId}`)
      .then(response => {
        // If deletion is successful, update the clients array to remove the deleted client
        setClients(clients.filter(client => client.Id !== clientId));
      })
      .catch(error => {
        console.error('Error deleting Auction:', error);
        // Handle error, such as displaying an error message
      });
  };

  const filterClientsByCategory = (client) => {
    const currentDate = new Date();
    const endDate = new Date(client.Enddate);
    const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    if (selectedCategory === "Expired") {
      return endDate < today;
    } else if (selectedCategory === "Upcoming") {
      return endDate > today;
    } else if (selectedCategory === "Today") {
      return endDate.toDateString() === today.toDateString();
    }
    return true; // Show all clients if no category is selected
  };

  return (
    <div style={{ paddingTop: 90 }}>
      <Container>
        <Row className='align-items-center'>
          <Col xl="12">
            <div>
              <div style={{ marginBottom: 10 }}>
                <h5 style={{ fontWeight: "bold", color: "brown", marginBottom: 0 }}>Listed Auctions</h5>
                <p style={{ color: 'GrayText' }}>The following control helps in reviewing and managing existing Auction records.</p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <div>
              <div style={{ padding: 10, margin: "10px 2px", border: "solid #fafafa 2px", borderRadius: "5px", minHeight: 30, backgroundColor: "white" }}>
                <h6 style={{ fontWeight: "bold", color: "black" }}>A. Manage Existing Records</h6>
                <hr style={{ color: "#2F2F2E", margin: "0.5rem 0" }} />
                <p style={{ marginBottom: "0.5rem" }}>Use the following datalist to edit / delete existing records.</p>

                {/* Dropdown for category */}
                <select value={selectedCategory} className="form-control-lg form-control" onChange={(e) => setSelectedCategory(e.target.value)} style={{ marginBottom: "10px" }}>
                  <option value="All">Show All Listed Auctions</option>
                  <option value="Expired">Show Auctions - Ended Previously</option>
                  <option value="Upcoming">Show Auctions - Ending on Upcoming Date</option>
                  <option value="Today">Show Auctions - Ending Today</option>
                </select>

                <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '20px' }}>
                  <thead>
                    <tr>
                      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left', color: "white", backgroundColor: "brown", fontWeight: "normal", fontSize: "0.9rem" }}>Auction Name</th>
                      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left', color: "white", backgroundColor: "brown", fontWeight: "normal", fontSize: "0.9rem" }}>Client</th>
                      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left', color: "white", backgroundColor: "brown", fontWeight: "normal", fontSize: "0.9rem" }}>Category</th>
                      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left', color: "white", backgroundColor: "brown", fontWeight: "normal", fontSize: "0.9rem" }}>End Date</th>
                      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left', color: "white", backgroundColor: "brown", fontWeight: "normal", fontSize: "0.9rem" }}>End Time</th>
                      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left', color: "white", backgroundColor: "brown", fontWeight: "normal", fontSize: "0.9rem" }}>Location</th>
                      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left', color: "white", backgroundColor: "teal", fontWeight: "normal", fontSize: "0.9rem" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {clients.filter(filterClientsByCategory).map(client => (
                      <tr key={client.Id} style={{ border: '1px solid #dddddd' }}>
                        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left', fontSize: "0.9rem", color: "black" }}><b>{client.Name}</b></td>
                        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left', fontSize: "0.9rem", color: "black" }}>{client.Client}</td>
                        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left', fontSize: "0.9rem", color: "black" }}>{client.Category}</td>
                        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left', fontSize: "0.9rem", color: "black" }}>{client.Enddate}</td>
                        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left', fontSize: "0.9rem", color: "black" }}>{client.Endtime
}</td>
        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left',  fontSize: "0.9rem", color:"black" }}>{client.Location}</td>
        <td style={{ border: '1px solid #dddddd', padding: '2px 8px', textAlign: 'left' }}>
        <Button className="btn-warning btn-small" style={{padding:"2px 5px", color:"black"}}>Manage</Button> &nbsp;
          <Button className="btn-secondary btn-small" style={{padding:"2px 5px"}} onClick={() => handleDelete(client.Id)}>Delete</Button>
        </td>
        
      </tr>
    ))}
  </tbody>
</table>


                        </div>
                        </div>
                    </Col>
                </Row>
                
            </Container>
        </div>
   
     
     
   

    
  )
}

export {FeatureOne}