import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row, Button } from "reactstrap";
import validation from '../Validations/Auction-All.js';
import CryptoJS from 'crypto-js';

function FeatureOne() {
  const history = useHistory();
  const encryptClientId = (clientId) => {
    const encrypted = CryptoJS.AES.encrypt(clientId.toString(), process.env.REACT_APP_ENCRYPTION_KEY).toString();
    return encodeURIComponent(encrypted); // To make it URL safe
  };

  const handleManage = (clientId) => {
    const encryptedClientId = encryptClientId(clientId);
    history.push(`/UploadExcel/${encryptedClientId}`);
  };

  const today = new Date().toISOString().split('T')[0];
  const [message, setMessage] = useState("");
  const [messageerror, setMessageerror] = useState("");
  const [values, setValues] = useState({
    name: '',
    enddate: '',
    endtime: '',
    client: '',
    category: '',
    location: ''
  })

  const [errors, setErrors] = useState({})
    
    const handleInput = (event) => {
      setValues(prev => ({...prev, [event.target.name]: [event.target.value]}))
    }
    
    const handleSubmit = (event) => 
      {
        event.preventDefault();
        setErrors(validation(values));
    
        if(errors.name === "" && errors.enddate === "" && errors.endtime === "" && errors.client === "" && errors.category === "")
          {
            axios.post('https://bidtowin-heroku-825dede58502.herokuapp.com/auctions', values)
            .then(res => {

              if(res.data === "Exists")
                {
                  setMessageerror("Auction Name Exists! Please Recheck & Try Again.");
                  setMessage("");
                }
                else
                {
                  setMessage("Operation Success! Record was created successfully!");
                  setMessageerror("");
                  event.target.reset();

                  // Fetch data from the API endpoint
                  axios.get('https://bidtowin-heroku-825dede58502.herokuapp.com/getAuctionsData')
                  .then(response => {
                    // Store the fetched data in the state
                    setClients(response.data);
                  })
                  .catch(error => {
                    console.error('Error fetching data:', error);
                  });
                }
              
            })
            .catch(err => console.log(err));
          }
      }

  var bgColors = { "light": "white",
  "greyish": "#2F2F2E"
};


  const [options, setOptions] = useState([]);

  const [optionsnew, setOptionsnew] = useState([]);

  useEffect(() => {

    axios.get('https://bidtowin-heroku-825dede58502.herokuapp.com/getClientsData')
      .then(response => {
        setOptions(response.data.map(item => ({ value: item.Id, label: item.Company })));
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });

      axios.get('https://bidtowin-heroku-825dede58502.herokuapp.com/getCategoriesData')
      .then(response => {
        setOptionsnew(response.data.map(itemnew => ({ value: itemnew.Id, label: itemnew.Company })));
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });

  }, []); 

  const [clients, setClients] = useState([]);

    useEffect(() => {
      // Fetch data from the API endpoint
      axios.get('https://bidtowin-heroku-825dede58502.herokuapp.com/getAuctionsData')
        .then(response => {
          // Store the fetched data in the state
          setClients(response.data);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }, []); // Run this effect only once when the component mounts 

    const handleDelete = (clientId) => {
      // Make an API call to delete the client
      axios.delete(`https://bidtowin-heroku-825dede58502.herokuapp.com/auctions/${clientId}`)
        .then(response => {
          // If deletion is successful, update the clients array to remove the deleted client
          setClients(clients.filter(client => client.Id !== clientId));
        })
        .catch(error => {
          console.error('Error deleting Auction:', error);
          // Handle error, such as displaying an error message
        });
    };

  return (

    <div style={{paddingTop:90}}>
            <Container>
            <Row className='align-items-center'>
                    <Col xl="12">
                        <div>
                            <div style={{marginBottom:10}}>
                           <h5 style={{fontWeight:"bold",color:"brown",marginBottom:0}}>Create / Manage Auctions</h5>
                           <p style={{color:'GrayText'}}>The following control helps in creating / managing new / existing Auction records.</p>
                           
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="5">
                        <div>
                        <div style={{padding:10, margin:"10px 2px", border:"solid #fafafa 2px", borderRadius:"5px",backgroundColor:bgColors.light}}>
                        {message && <p style={{padding:5, backgroundColor:"green", color:"white", borderRadius:2}}>{message}</p>}
                        {messageerror && <p style={{padding:5, backgroundColor:"red", color:"white", borderRadius:2}}>{messageerror}</p>}
                           <h6 style={{fontWeight:"bold",color:"black"}}>A. Create New Record</h6>
                           <hr style={{color:bgColors.greyish,margin:"0.5rem 0"}}/>
                           <p style={{marginBottom:"0.5rem"}}>Use the following form to create new Auction record.</p>
                         
                           <form className="is-alter" action="" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label className="form-label" htmlFor="name">
                     Auction Title 
                    </label>
                    <div className="form-control-wrap">
                      <input onChange={handleInput}
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Enter Auction Name"
                        className="form-control-lg form-control"
                      />
                       {errors.name && <span className="text-danger" style={{fontSize:"0.8rem", fontWeight:"bolder"}}>{errors.name}</span>}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="enddate">
                      End Date
                    </label>
                    <div className="form-control-wrap">
                      <input onChange={handleInput}
                        type="date"
                        id="enddate"
                        name="enddate"
                        min={today}
                        placeholder="Select End Date"
                        className="form-control-lg form-control"
                      />
                      {errors.enddate && <span className="text-danger" style={{fontSize:"0.8rem", fontWeight:"bolder"}}>{errors.enddate}</span>}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="endtime">
                     End Time
                    </label>
                    <div className="form-control-wrap">
                      <input onChange={handleInput}
                        type="time"
                        id="endtime"
                        name="endtime"
                        placeholder="Select End Time"
                        className="form-control-lg form-control"
                      />
                      {errors.endtime && <span className="text-danger" style={{fontSize:"0.8rem", fontWeight:"bolder"}}>{errors.endtime}</span>}
                    </div>
                  </div>
                  <div className="form-group">
                  <label className="form-label" htmlFor="client">
                     Select a Client
                    </label>
                    <div className="form-control-wrap">
      <select  id="client" name="client" className="form-control-lg form-control" onChange={handleInput}>
        <option value="">Select...</option>
        {options.map(option => (
          <option key={option.Id} value={option.Company}>
            {option.label}
          </option>
        ))}
      </select>
      {errors.client && <span className="text-danger" style={{fontSize:"0.8rem", fontWeight:"bolder"}}>{errors.client}</span>}
      </div>
      </div>
      <div className="form-group">
      <label className="form-label" htmlFor="category">
                      Select a Category
                    </label>
                    <div className="form-control-wrap">
      <select id="category" name="category" className="form-control-lg form-control" onChange={handleInput}>
        <option value="">Select...</option>
        {optionsnew.map(optionnew => (
          <option key={optionnew.Id} value={optionnew.Company}>
            {optionnew.label}
          </option>
        ))}
      </select>
      {errors.category && <span className="text-danger" style={{fontSize:"0.8rem", fontWeight:"bolder"}}>{errors.category}</span>}
      </div>
      </div>
      
      <div className="form-group">
                    <label className="form-label" htmlFor="location">
                      Select Zone / Location
                    </label>
                    <div className="form-control-wrap">
<select  id="location" name="location" className="form-control-lg form-control" onChange={handleInput}>
                  <option value="North">North</option>
                  <option value="East">East</option>
                  <option value="West">West</option>
                  <option value="South">South</option>
                  <option value="West">Pan India</option>
                  <option value="South">Others</option>
                </select>
                    </div>
                  </div>
               
             
                  <div className="form-group">
                    <Button type="submit" color="primary" size="lg" className="btn-block">
                      Create Record 
                    </Button>
                  </div>
                </form>
                          
                            </div>
                          
                           
                           
                        </div>
                    </Col>
                    <Col  md="7">
                        <div>
                        <div style={{padding:10, margin:"10px 2px", border:"solid #fafafa 2px", borderRadius:"5px", minHeight:30,backgroundColor:bgColors.light}}>
                        <h6 style={{fontWeight:"bold",color:"black"}}>B. Manage Existing Records</h6>
                        <hr style={{color:bgColors.greyish,margin:"0.5rem 0"}}/>
                        <p style={{marginBottom:"0.5rem"}}>Use the following datalist to edit / delete existing records.</p>

                      
                        <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '20px' }}>
  <thead>
    <tr>
      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left', color:"white", backgroundColor:"brown", fontWeight:"normal", fontSize: "0.9rem" }}>Auction Details / End Timestamp</th>
      <th style={{ border: '1px solid #dddddd', padding: '4px 4px', textAlign: 'left',  color:"white", backgroundColor:"teal", fontWeight:"normal", fontSize: "0.9rem" }}>Actions</th>
    </tr>
  </thead>
  <tbody>
    {clients.map(client => (
      <tr key={client.Id} style={{ border: '1px solid #dddddd' }}>
        <td style={{ border: '1px solid #dddddd', padding: '2px 4px', textAlign: 'left',  fontSize: "0.9rem", color:"black" }}><b>{client.Name}</b><br/>{client.Client} / {client.Category} / {client.Enddate} / {client.Endtime} / {client.Location}<br/><b>Unique Identification ID</b>: BTW{client.Id}</td>
        <td style={{ border: '1px solid #dddddd', padding: '2px 8px', textAlign: 'left' }}>
        <Button className="btn-warning btn-small" style={{padding:"2px 5px", color:"black"}} onClick={() => handleManage(client.Id)}>Manage</Button> &nbsp;
          <Button className="btn-secondary btn-small" style={{padding:"2px 5px"}} onClick={() => {
    if (window.confirm("Are you sure you want to delete this record?")) {
      handleDelete(client.Id);
    }
  }}>Delete</Button>
        </td>
        
      </tr>
    ))}
  </tbody>
</table>


                        </div>
                        </div>
                    </Col>
                </Row>
                
            </Container>
        </div>
   
     
     
   

    
  )
}

export {FeatureOne}