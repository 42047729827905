import React, {useState} from "react"
import { Section, SectionHead } from "../../layout/section/Section"
import { Container, Row, Col, Card, Form,  Input, Button } from "reactstrap"
import { TextBlock, TitleH3 } from "../../components/textBlock/TextBlock"
import { Link } from "../../components/button/Button"
import emailjs from '@emailjs/browser';


 

const CtaOne = (props) =>{

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [message, setMessage] = useState("");

    const sendEmail = (e) => {
        e.preventDefault();
        setIsSubmitting(true); // Disable the button immediately
        emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, e.target, process.env.REACT_APP_PUBLIC_KEY);
        setMessage("Message was Sent Successfully!");
        e.target.reset();
        setIsSubmitting(false); // Re-enable the button after submission
     }

 const NumericOnly= (e) => { 
         const reg = /^[0-9\b]+$/
         let preval=e.target.value
         if (e.target.value === '' || reg.test(e.target.value)) return true
         else e.target.value = preval.substring(0,(preval.length-1))
    }

    const LettersOnly= (e) => { 
     const reg = /^[a-zA-Z\s]+$/
     let preval=e.target.value
     if (e.target.value === '' || reg.test(e.target.value)) return true
     else e.target.value = preval.substring(0,(preval.length-1))
 } 
    return(
        <Section className={props.className && props.className} id={props.id && props.id}>
            <Container>
                <Row className="justify-content-center">
                    <Col xl="10">
                        <Row className="align-items-center g-0">
                            <Col md="7">
                                <Card className="card-shadow round-xl bg-indigo is-dark pb-4 pb-md-0">
                                    <div className="card-inner card-inner-xl">
                                        <TextBlock>
                                            <TitleH3>Hey, We are Listening!</TitleH3>
                                        </TextBlock>
                                        <ul className="list list-nostyle fs-16px">
                                            <li><b style={{color: "whitesmoke"}}>Call Us On</b><br/>(+91) 092053 54869</li>
                                            <li><b style={{color: "whitesmoke"}}>Write To Us</b><br/>info@bidtowin.in</li>
                                            <li><b style={{color: "whitesmoke"}}>Corporate Address</b><br/>Himalaya Enclave,<br/>Vrindavan Colony,<br/>Lucknow - 226029</li>
                                        
                                            <li className="note text-warning">All support executives are available 24X7<br/>to assist you in any manner. Happy Bidding!</li>
                                        </ul>
                                        <ul className="btns-inline">
                                            <li><Link to="/" target="_blank" rel="noreferrer"  className="btn-xl btn-primary">Android App Available!</Link></li>
                                        </ul>
                                    </div>
                                </Card>
                            </Col>
                            <Col md="5">
                                <Card className="card-shadow round-xl ms-lg-n7 ms-md-n5 mx-4 me-md-0 mt-md-0 mt-n4">
                                    <div className="card-inner card-inner-lg">
                                        <div className="form-block">
                                            <SectionHead className="section-head-sm">
                                            {message && <p style={{padding:5, backgroundColor:"green", color:"white", borderRadius:2}}>{message}</p>}
                                                <h4 className="title">Feel free to leave us a message!</h4>
                                            </SectionHead>
                                            <Form className="form-submit" onSubmit={sendEmail}>
                                                <Row className="g-4">
                                                    <Col xs="12">
                                                        <div className="form-group">
                                                            <label className="form-label" htmlFor="name">Full Name</label>
                                                            <div className="form-control-wrap">
                                                                <Input type="text" onChange={LettersOnly} className="form-control form-control-lg" name="name_from" id="name" placeholder="Enter Your Name" required />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xs="12">
                                                        <div className="form-group">
                                                            <label className="form-label" htmlFor="name">Communication Email Address</label>
                                                            <div className="form-control-wrap">
                                                                <Input type="email" className="form-control form-control-lg" name="emailer_from" id="email" placeholder="Enter Your Email" required />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xs="12">
                                                        <div className="form-group">
                                                            <label className="form-label" htmlFor="name">IN Contact No.</label>
                                                            <div className="form-control-wrap">
                                                                <Input ttype="text" onChange={NumericOnly} className="form-control form-control-lg" name="contact_from" maxLength="10" id="contact" placeholder="Enter 10 Digit Contact No." required  />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xs="12">
                                                        <div className="form-group">
                                                            <label className="form-label" htmlFor="question">Your Question / Message</label>
                                                            <div className="form-control-wrap">
                                                                <textarea className="form-control no-resize" name="message_from" id="question" cols="20" rows="5" placeholder="Write your message here..." required />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xs="12">
                                                    <Button type="submit" disabled={isSubmitting} className="btn-danger btn-lg">
                                                       {isSubmitting ? 'Please Wait...' : 'Send Message'}
                                                    </Button>
                                                        {/* <Link to={`${process.env.PUBLIC_URL}/`} className="btn-danger btn-lg">Send Message</Link> */}
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Col>  
                </Row>
            </Container>
        </Section>
    )
}

export default CtaOne
