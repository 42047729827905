import React, { useState } from "react";

import Logo from "./Resc/header-white.png";
import LogoDark from "./Resc/header-black.png";

import background from "./Resc/loginbag.jpg";

import PageContainer from "./layout/page-container/PageContainer";
import Head from "./layout/head/Head";

import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { NioIcon } from "./components/icon/Icon";
import validation from './Validations/Login-Email.js';
import validationnew from './Validations/Login-Password.js';
import cookies, {useCookies} from "react-cookie";

const Login = () => {
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cookies, setCookie] = useCookies(["token"]);
  
  const [errors, setErrors] = useState({})
  const [errorsnew, setErrorsnew] = useState({})
  
  
  const [passState, setPassState] = useState(false);
  const handleSubmit =(event) => 
    {
      event.preventDefault();
      setErrors(validation(email));
      setErrorsnew(validationnew(password));
     

       if(errors.email === "" && errorsnew.password === "")
         {
          setIsSubmitting(true); // Disable the button immediately
      const userData = {
        email,
        password
      };
          axios.post('https://bidtowin-heroku-825dede58502.herokuapp.com/administrator', userData)
          .then(res => {

            if(res.data === "Success")
              {
                      setCookie("token", email, { path: "/" });
                      window.location.href = "/dashboard";
                
              }
              else
              {
                // alert("Invalid Credentials! Please Try Again.")
                setMessage("Invalid Login Credentials! Try again or contact Support.");
                setIsSubmitting(false); // Re-enable the button after submission
              }
            
          })
          .catch(err => console.log(err));
        }
    }
  return (
    <React.Fragment>
      <Head title="Login" />
      <PageContainer>

<div style={{backgroundImage: `url(${background})`, height: "100vh"}}>
        
        <div className="nk-block nk-block-middle nk-auth-body wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
            </Link>
          </div>

          <div className="card-preview card-bordered" style={{backgroundColor: "white"}}>
            <div className="card-inner card-inner-lg">
              <div className="nk-block-head">
                <div className="nk-block-content">
                {message && <p style={{padding:5, backgroundColor:"red", color:"white", borderRadius:2}}>{message}  <Link to="/"><strong style={{color:"white", textDecoration:"underline", float:"right"}}>Return Home</strong></Link></p>}
                  <h5 className="nk-block-title page" style={{fontSize: 17, fontWeight: "bolder"}}>EMPLOYEE EXTRANET</h5>
                  <div className="nk-block-des">
                  <p>Access your dashboard using your registered email address and secure passcode.</p>
                  </div >
                </div>
              </div>
            <form className="is-alter" action="" onSubmit={handleSubmit}> 
                <div className="form-group">
                  <div className="form-label-group">
                    <label className="form-label" htmlFor="email">
                      Reg. Email Address
                    </label>
                  </div>
                  <div className="form-control-wrap">
                    <input onChange={(e) => setEmail(e.target.value)}
                      type="text"
                      id="email"
                      name="email"
                      value={email}
                      placeholder="Enter Reg. Email Address"
                      className="form-control-lg form-control"
                    /> {errors.email && <span className="text-danger" style={{fontSize:"0.8rem", fontWeight:"bolder"}}>{errors.email}</span>}
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-label-group">
                    <label className="form-label" htmlFor="password">
                      Your Secure Passcode
                    </label>
                  </div>
                  <div className="form-control-wrap">
                    <a
                      href="#password"
                      onClick={(ev) => {
                        ev.preventDefault();
                        setPassState(!passState);
                      }}
                      className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                    >
                      <NioIcon icon="eye" className="passcode-icon icon-show"></NioIcon>

                      <NioIcon icon="eye-off" className="passcode-icon icon-hide"></NioIcon>
                    </a>
                    <input
                      type={passState ? "text" : "password"} onChange={(e) => setPassword(e.target.value)}
                      id="password"
                      name="password"
                      value={password}
                      placeholder="Enter Secure Password"
                      className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                    /> {errorsnew.password && <span className="text-danger" style={{fontSize:"0.8rem", fontWeight:"bolder"}}>{errorsnew.password}</span>}
                  </div>
                </div>
                <div className="form-group">
                  <Button size="lg" disabled={isSubmitting} className="btn-block" type="submit" color="primary">
                  {isSubmitting ? 'Please Wait...' : 'Continue To Extranet'}
                  </Button>
                </div>
             
              </form>
              <div className="form-note-s2 text-center pt-4">
                Accidently landed here? <Link to={`${process.env.PUBLIC_URL}/`}>Return Home</Link>
              </div>
              
              </div>
            </div>
          </div>
          </div>
      </PageContainer>
    </React.Fragment>
  );
};
export default Login;
